// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useLayoutEffect, useState } from 'react';

const useMediaQuery = query => {
    const [value, setValue] = useState(false);

    useLayoutEffect(() => {
        const mq = window.matchMedia(query);

        const updateMatch = () => {
            setValue(mq.matches);
        };
        updateMatch();

        mq.addListener(updateMatch);
        return () => {
            mq.removeListener(updateMatch);
        };
    }, [query]);

    return value;
};

export default useMediaQuery;
