// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { FormFieldText, FormFieldRadioButtons, Heading } from '@plesk/ui-library';
import { Locale } from 'jsw';
import { addToast } from 'helpers/toasts';
import Form from 'plesk/components/Form';
import Link from 'plesk/components/Link';
import { withLoginLayout } from '../layouts/LoginLayout';

const Translate = Locale.getTranslate('components.forms.get-password');

const GetPasswordPage = ({ values: defaultValues }) => {
    const navigate = useNavigate();
    const values = useMemo(() => ({
        resetBy: 'email',
        email: '',
        login: '',
        ...defaultValues,
    }), [defaultValues]);

    const handleSuccess = useCallback(() => {
        addToast({
            intent: 'success',
            message: <Translate content="messageSend" />,
        });
        navigate('/login_up.php');
    }, [navigate]);

    return (
        <>
            <Heading level="3"><Translate content="label" /></Heading>
            <p><Translate content="text" /></p>
            <Form
                id="form-changepassword-getlink"
                values={values}
                onSuccess={handleSuccess}
                applyButton={false}
                submitButton={{
                    children: <Translate content="send" />,
                    'data-action': 'send',
                }}
                cancelButton={{
                    component: Link,
                    to: '/login_up.php',
                    children: <Translate content="cancel" />,
                    'data-action': 'cancel',
                }}
                vertical
            >
                <FormFieldRadioButtons
                    name="resetBy"
                    options={[
                        {
                            value: 'email',
                            label: <Translate content="emailLabel" />,
                            indentFields: [
                                <FormFieldText
                                    key="reset-by-email"
                                    name="email"
                                    autoFocus
                                    size="fill"
                                />,
                            ],
                        },
                        {
                            value: 'login',
                            label: <Translate content="loginLabel" />,
                            indentFields: [
                                <FormFieldText
                                    key="reset-by-login"
                                    name="login"
                                    size="fill"
                                />,
                            ],
                        },
                    ]}
                />
            </Form>
        </>
    );
};

GetPasswordPage.propTypes = {
    values: PropTypes.shape({
        resetBy: PropTypes.string,
        email: PropTypes.string,
        login: PropTypes.string,
    }),
};

GetPasswordPage.defaultProps = {
    values: undefined,
};

export default withLoginLayout(GetPasswordPage);
