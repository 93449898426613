// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { usePageData } from 'helpers/pageData';
import PageLayout from 'components/layouts/PageLayout';

const PhpPage = () => {
    const location = useLocation();
    const data = usePageData();

    useEffect(() => () => {
        if (location.pathname.startsWith('/smb/log-file/browser')) {
            // TODO Need to rework this page to client routing and move this code to it unmount hook
            Jsw.getComponent('log-browser')?.remove();
        }
    }, [location.pathname]);

    return (
        <PageLayout {...data} />
    );
};

export default PhpPage;
