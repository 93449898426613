// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Tooltip, Locale } from 'jsw';
import { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Dropdown, Menu, MenuItem, Button, Icon, isRtl, Text } from '@plesk/ui-library';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from 'common/hooks';
import SubscriptionSwitcher from './SubscriptionSwitcher';
import NotificationCenter from './NotificationCenter';
import HelpMenu from './HelpMenu';

import ACCOUNT_MENU_QUERY from 'queries/AccountMenu.graphql';
import MAIN_HEADER_NAVBAR_QUERY from 'queries/MainHeaderNavbar.graphql';

const Translate = Locale.getTranslate('components.shortcuts');

const AccountMenu = ({ items }) => {
    const isHideText = useMediaQuery('(max-width: 767px)');
    const { data: { viewer: user } = {} } = useQuery(ACCOUNT_MENU_QUERY);

    if (!user) {
        return null;
    }

    return (
        <Dropdown
            menu={(
                <Menu>
                    {items.map(({ title, href, icon, dataType }) => (
                        <MenuItem
                            key={href}
                            component="a"
                            icon={icon}
                            href={href}
                            data-type={dataType}
                        >
                            {title}
                        </MenuItem>
                    ))}
                </Menu>
            )}
            menuPlacement="bottom-end"
        >
            <Button id="account-menu" icon="user" tooltip={<Translate content="loggedInAs" />} ghost caret={!isHideText}>
                {isHideText ? null : <Text truncate>{user.pname || user.login}</Text>}
            </Button>
        </Dropdown>
    );
};

AccountMenu.propTypes = {
    items: PropTypes.array.isRequired,
};

const renderTooltips = () => {
    document.querySelectorAll('.main-header-navbar-nav__item').forEach(item => {
        if (item.dataset.description) {
            Tooltip.init(item, {
                text: item.dataset.description,
            });
        }
    });
};

const MainHeaderNavbar = ({
    backToAdminLink,
    returnUrl,
    accountMenu,
    subscriptionSwitcher,
    headerCustomButtons,
    helpMenu,
}) => {
    const isHideBackToAdminText = useMediaQuery('(max-width: 1310px)');
    const isHideLogo = useMediaQuery('(max-width: 1140px)');
    const { data: { viewer: user, config } = {} } = useQuery(MAIN_HEADER_NAVBAR_QUERY);

    useEffect(() => {
        renderTooltips();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="main-header-navbar">
            <ul className="main-header-navbar-nav">
                {backToAdminLink ? (
                    <li className="top-back">
                        <Button
                            component="a"
                            icon={<Icon name="chevron-left" flipHorizontal={isRtl()} />}
                            href={backToAdminLink}
                            ghost
                        >
                            {isHideBackToAdminText ? null : <Translate content="backToAdmin" />}
                        </Button>
                    </li>
                ) : null}
                {accountMenu ? (
                    <li className="top-user">
                        <AccountMenu {...accountMenu} />
                    </li>
                ) : null}
                {accountMenu && subscriptionSwitcher ? (
                    <li className="top-webspace">
                        <SubscriptionSwitcher
                            returnUrl={returnUrl}
                            {...subscriptionSwitcher}
                        />
                    </li>
                ) : null}
                {user?.type === 'ADMIN' && config?.notification?.panel?.enabled ? (
                    <li className="top-notification-center">
                        <NotificationCenter />
                    </li>
                ) : null}
                {headerCustomButtons.map(({ id, link, target, icon, title, description }) => (
                    <li
                        key={id}
                        id={`extNavHeader-${id}`}
                        className={classNames('main-header-navbar-nav__item', `main-header-navbar-nav__item--${id}`)}
                        data-description={description}
                    >
                        <Button
                            component="a"
                            href={link}
                            target={target}
                            rel={target ? 'noopener noreferrer' : null}
                            icon={<Icon src={id === 'advisor-1' ? require('icons/16/plesk/top-advisor.svg') : icon} alt={title} />}
                            ghost
                        />
                    </li>
                ))}
                {helpMenu ? (
                    <li className="top-help">
                        <HelpMenu {...helpMenu} />
                    </li>
                ) : null}
                {user.logo.isCustom && !isHideLogo ? (
                    <li className="top-logo">
                        <a href="http://www.plesk.com" target="_blank" rel="noopener noreferrer">
                            <img src={require('images/logos/powered-logo.png')} alt="" />
                        </a>
                    </li>
                ) : null}
            </ul>
        </div>
    );
};

MainHeaderNavbar.propTypes = {
    backToAdminLink: PropTypes.string,
    returnUrl: PropTypes.string,
    accountMenu: PropTypes.shape(AccountMenu.propTypes),
    subscriptionSwitcher: PropTypes.shape(SubscriptionSwitcher.propTypes),
    headerCustomButtons: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        link: PropTypes.string,
        target: PropTypes.string,
        icon: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.string,
    })).isRequired,
    helpMenu: PropTypes.shape(HelpMenu.propTypes),
};

MainHeaderNavbar.defaultProps = {
    backToAdminLink: undefined,
    returnUrl: undefined,
    accountMenu: undefined,
    subscriptionSwitcher: undefined,
    helpMenu: undefined,
};

export default MainHeaderNavbar;
