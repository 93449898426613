// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Button } from './button';
import render from './render';
import escapeHtml from './escapeHtml';

export class BigButton extends Button {
    _initConfiguration(config) {
        super._initConfiguration({
            cls: 'tool-block',
            disabledClass: 'tool-block-disabled',
            ...config,
        });
    }

    _initComponentElement() {
        super._initComponentElement();

        let button = '';
        if (this._getConfigParam('title')) {
            button += `<span class="tool-name">${escapeHtml(this._getConfigParam('title'))}</span>`;
        }

        let description = '';
        if (Array.isArray(this._getConfigParam('additionalComments'))) {
            this._getConfigParam('additionalComments').forEach(comment => {
                description += `${escapeHtml(comment)}<br/>`;
            });
            description = `<span>${description}</span>`;
        }
        if (this._getConfigParam('comment')) {
            description += escapeHtml(this._getConfigParam('comment'));
        }
        if (description.length) {
            button += `<span class="tool-info">${description}</span>`;
        }
        if (this._getConfigParam('icon')) {
            const icon = (
                '<span class="tool-icon">' +
                    `<img src="${this._getConfigParam('icon')}">` +
                '</span>'
            );
            button = icon + button;
        }
        this._componentElement.innerHTML = button;
    }

    _addProgressHandler() {
        if (this._getConfigParam('indicateProgress', false)) {
            this.addEventObserver('click', () => {
                this._componentElement.style.display = 'none';
                render(this._componentElement.parentNode, `<div class="ajax-loading">${this._config.waitMessage}</div>`);
            });
        }
    }
}
