// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import './styles/index.less';
import './app/index.less';
import './components/index.less';
import './views/index.less';

import './polyfill';

import * as Jsw from './jsw';
import * as Plesk from './plesk';
import './legacy';

window.Jsw = Jsw;
window.Plesk = Plesk;
