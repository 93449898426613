// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { Text, Link } from '@plesk/ui-library';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { UAT, Locale } from 'jsw';
import { NotificationPropType } from './lib/PropTypes';

const Translate = Locale.getTranslate('components.notification-center');

const NotificationToast = ({
    notification,
    onSelectNotification,
}) => {
    const { subject, context, code, createdAt, messageKey } = notification;

    useEffect(() => {
        UAT.dispatchAction('NOTIFICATION_TOAST_SHOW', {
            createdAt,
            messageKey,
            type: code,
        });

        return () => {
            UAT.dispatchAction('NOTIFICATION_TOAST_HIDE', {
                type: code,
                messageKey,
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Text>{context}</Text>
            <br />
            <Text bold>{subject}</Text>
            <br />
            <Link
                data-action="NOTIFICATION_DETAILS"
                data-type={code}
                onClick={() => onSelectNotification({ notification })}
            >
                <Translate content="learnMore" />
            </Link>
        </>
    );
};

NotificationToast.propTypes = {
    notification: NotificationPropType.isRequired,
    onSelectNotification: PropTypes.func.isRequired,
};

export default NotificationToast;
