// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { generatePath, parsePath } from 'react-router';
import { matchPath } from 'react-router-dom';
import { gql } from '@apollo/client';
import { api } from 'jsw';
import pageData from '../helpers/pageData';
import apolloClient from './apolloClient';

const asyncElement = (moduleLoader, ...propsLoaders) => async props => {
    const [loadedModule, { redirect, ...loadedProps }] = await Promise.all([
        moduleLoader(props),
        Promise.all(propsLoaders.map(propsLoader => propsLoader(props)))
            .then(loadedProps => loadedProps.reduce((prev, curr) => ({ ...prev, ...curr }), props)),
    ]);

    if (redirect) {
        return { redirect };
    }

    if (props.pathname.startsWith('/cp') || props.pathname.startsWith('/get_password.php')) {
        const { isInitial, status, ...data } = pageData();
        pageData({
            ...data,
            status: status?.filter(message => isInitial || message.isNote),
        });
    }

    const Component = loadedModule.default || loadedModule;
    return (
        <Component {...loadedProps} />
    );
};

const initialDataLoader = () => {
    const { isInitial, ...data } = pageData();
    pageData(data);

    return isInitial ? data : null;
};

const createPageDataLoader = dataPath => async ({ pathname, params }) => {
    const { redirect, graphqlQueries = [], ...newData } = initialDataLoader()
        ?? await api.get((dataPath ? generatePath(dataPath, params) : pathname) + window.location.search);

    if (redirect) {
        return { redirect };
    }

    graphqlQueries.forEach(({ query, ...options }) => {
        try {
            apolloClient.writeQuery({
                query: gql(query),
                ...options,
            });
        } catch {}
    });

    pageData(newData);

    return newData;
};

const createGraphqlLoader = (query, variables, fetchPolicy = 'cache-first') => async () => {
    try {
        const { data } = await apolloClient.query({ query, variables, fetchPolicy });
        return data;
    } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        return {
            redirect: '/',
        };
    }
};

let routes = [
    {
        path: ['/login_up.php', '/login_up.php3', '/login'],
        element: asyncElement(
            () => import(/* webpackMode: "eager" */'components/pages/LoginPage'),
            initialDataLoader,
            createGraphqlLoader(require('queries/LoginPage.graphql')),
        ),
    },
    {
        path: '/get_password.php',
        element: asyncElement(
            () => import(/* webpackMode: "eager" */'components/pages/GetPasswordPage'),
            initialDataLoader,
        ),
    },
    {
        path: '/admin/force-reset-password',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/force-reset-password" */'views/admin/force-reset-password'),
            createPageDataLoader('/admin/force-reset-password'),
        ),
    },
    {
        path: '/ch_pass_by_secret.php',
        element: asyncElement(
            () => import(/* webpackMode: "eager" */'components/pages/RestorePasswordPage'),
        ),
    },
    {
        path: '/admin/setup/progress',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/setup/progress" */'views/admin/setup/progress'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/setup',
        end: false,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/setup/index" */'views/admin/setup/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/home',
        end: false,
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/home/admin" */'views/admin/home/admin'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/app',
        element: createPageDataLoader(),
    },
    {
        path: '/admin/app/info/id/:id',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/app/info" */'views/admin/app/info'),
            createPageDataLoader('/admin/app/info/id/:id'),
        ),
    },
    {
        path: '/admin/app/usage',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/app/usage" */'views/admin/app/usage'),
            createPageDataLoader('/admin/app/usage'),
        ),
    },
    {
        path: '/admin/app/uploaded',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/app/uploaded" */'views/admin/app/uploaded'),
            createPageDataLoader('/admin/app/uploaded'),
        ),
    },
    {
        path: '/admin/custom-buttons/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/custom-buttons/list" */'views/admin/custom-buttons/list'),
            createPageDataLoader('/admin/custom-buttons/list'),
        ),
    },
    {
        path: '/smb/custom-buttons/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/custom-buttons/list" */'views/admin/custom-buttons/list'),
            createPageDataLoader('/smb/custom-buttons/list'),
        ),
    },
    {
        path: '/admin/ftp-sessions/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/ftp-sessions/list" */'views/admin/ftp-sessions/list'),
            createPageDataLoader('/admin/ftp-sessions/list'),
        ),
    },
    {
        path: '/admin/php-handler/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/php-handler/list" */'views/admin/php-handler/list'),
            createPageDataLoader('/admin/php-handler/list'),
        ),
    },
    {
        path: ['/admin/scheduler', '/smb/scheduler'],
        element: createPageDataLoader(),
    },
    {
        path: ['/admin/scheduler/tasks-list', '/smb/scheduler/tasks-list'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/default/scheduler/list" */'views/default/scheduler/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/admin/domain/list', '/admin/domain/add-domain'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/domain/list" */'components/pages/DomainsPage'),
            createPageDataLoader('/admin/domain/list'),
        ),
    },
    {
        path: ['/admin/customer/list', '/admin/customer/create'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/customer/list" */'components/pages/CustomersPage'),
            createPageDataLoader('/admin/customer/list'),
        ),
    },
    {
        path: '/admin/customer/overview/id/:id',
        element: createPageDataLoader(),
    },
    {
        path: ['/admin/customer/domains/id/:id', '/admin/customer/add-domain/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/customer/domains" */'components/pages/CustomerDomainsPage'),
            createPageDataLoader('/admin/customer/domains/id/:id'),
        ),
    },
    {
        path: ['/admin/customer/subscriptions/id/:id', '/admin/customer/new-subscription/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/customer/subscriptions" */'components/pages/CustomerSubscriptionsPage'),
            createPageDataLoader('/admin/customer/subscriptions/id/:id'),
        ),
    },
    {
        path: '/admin/reseller/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/list" */'components/pages/ResellersPage'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/reseller/overview/id/:id',
        element: createPageDataLoader(),
    },
    {
        path: ['/admin/reseller/customers/id/:id', '/admin/reseller/new-customer/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/customers" */'components/pages/ResellerCustomersPage'),
            createPageDataLoader('/admin/reseller/customers/id/:id'),
        ),
    },
    {
        path: ['/admin/reseller/domains/id/:id', '/admin/reseller/add-domain/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/domains" */'components/pages/ResellerDomainsPage'),
            createPageDataLoader('/admin/reseller/domains/id/:id'),
        ),
    },
    {
        path: ['/admin/reseller/subscriptions/id/:id', '/admin/reseller/new-subscription/id/:id'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/reseller/subscriptions" */'components/pages/ResellerSubscriptionsPage'),
            createPageDataLoader('/admin/reseller/subscriptions/id/:id'),
        ),
    },
    {
        path: '/admin/server/tools',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/server/tools" */'views/admin/server/tools'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/server/preview-domain-settings',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/server/preview-domain-settings"*/'views/admin/server/preview-domain-settings'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/services/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/services/list" */'views/admin/services/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/sessions/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/sessions/list" */'views/admin/sessions/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/admin/subscription/list', '/admin/subscription/create'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/subscription/list" */'components/pages/SubscriptionsPage'),
            createPageDataLoader('/admin/subscription/list'),
        ),
    },
    {
        path: ['/admin/subscription/login/all/:all/id/:id/', '/admin/subscription/login/id/:id/'],
        element: createPageDataLoader(),
    },
    {
        path: '/admin/subscription/overview/id/:id/',
        element: createPageDataLoader(),
    },
    {
        path: ['/smb/file-manager/list', '/smb/file-manager/list/domainId/:domainId'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/file-manager/list" */'views/smb/file-manager/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/file-manager',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/file-manager/index" */'views/smb/file-manager/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/my-service',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/my-service/index" */'views/smb/my-service/index'),
            createPageDataLoader('/smb/my-service'),
        ),
    },
    {
        path: ['/smb/email-address', '/smb/email-address/index', '/smb/email-address/index/domainId/:domainId'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/email-address/index" */'views/smb/email-address/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/email-address/list', '/smb/email-address/list/domainId/:domainId'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/email-address/list" */'views/smb/email-address/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/mail-settings/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/mail-settings/list" */'views/smb/mail-settings/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/account', '/smb/account/index'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/account/index" */'views/smb/account/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/account/show/id/:id',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/account/show" */'views/smb/account/show'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/user/index',
        element: createPageDataLoader(),
    },
    {
        path: '/smb/user/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/user/list" */'components/pages/UsersPage'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/web/setup', '/smb/web/add-domain/create-webspace/true'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/setup" */'views/smb/web/setup'),
            createPageDataLoader('/smb/web/setup'),
        ),
    },
    {
        path: ['/smb/web/view', '/smb/web/view/id/:id/type/:type', '/smb/web/add-domain'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/view" */'views/smb/web/view'),
            createPageDataLoader('/smb/web/view'),
        ),
    },
    {
        path: '/smb/web/overview/id/:id/type/:type',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/view" */'views/smb/web/view'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/web/php-settings/id/:id',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/php-settings" */'views/smb/web/php-settings'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/statistics/details',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/statistics/details" */'views/smb/statistics/details'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/statistics/list',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/statistics/details" */'views/smb/statistics/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/statistics',
        element: createPageDataLoader(),
    },
    {
        path: ['/smb/account/switch/all/:all/id/:id', '/smb/account/switch/id/:id'],
        element: createPageDataLoader(),
    },
    {
        path: '/smb/database',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/database/index" */'views/smb/database/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: ['/smb/database/list', '/smb/database/list/domainId/:domainId'],
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/database/list" */'views/smb/database/list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/database/users-list/domainId/:domainId',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/database/users-list" */'views/smb/database/users-list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/task-manager',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/task-manager/index" */'views/admin/task-manager/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/dns/index',
        element: createPageDataLoader(),
    },
    {
        path: '/cp/dns/records',
        element: async props => {
            const { data: { viewer: { type, permissions: { manageServerDnsTemplate } }, mode: { isDnsServiceSupported } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDnsTemplate
                            }
                        }
                        mode {
                            isDnsServiceSupported
                        }
                    }
                ` });
            if (type === 'ADMIN' && manageServerDnsTemplate && isDnsServiceSupported) {
                return asyncElement(
                    () => import(/* webpackChunkName: "views/dns/records" */'components/pages/DnsSettingsPage/Records'),
                    createGraphqlLoader(require('queries/DnsRecordsPage.graphql')),
                )(props);
            }

            return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
        },
    },
    {
        path: '/cp/dns/soa-record',
        element: async props => {
            const { data: { viewer: { type, permissions: { manageServerDnsTemplate } }, mode: { isDnsServiceSupported } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDnsTemplate
                            }
                        }
                        mode {
                            isDnsServiceSupported
                        }
                    }
                ` });
            if (type === 'ADMIN' && manageServerDnsTemplate && isDnsServiceSupported) {
                return asyncElement(
                    () => import(/* webpackChunkName: "views/dns/soa-record" */'components/pages/DnsSettingsPage/SoaRecord'),
                    createGraphqlLoader(require('queries/DnsSoaRecordsPage.graphql')),
                )(props);
            }
            return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
        },
    },
    {
        path: '/admin/dns/acl-records',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/dns/acl-records" */'views/admin/dns/acl-records'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/dns/add-acl-record',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/dns/add-acl-record" */'views/admin/dns/add-acl-record'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/dns/external-dns',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/dns/external-dns" */'views/admin/dns/external-dns'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/dns/external-dns-edit/extensionId/:extensionId',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/dns/external-dns-edit" */'views/admin/dns/external-dns-edit'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/index/id/:id/type/:type',
        element: createPageDataLoader(),
    },
    {
        path: '/smb/dns-zone/records-list/id/:id/type/:type',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/records-list" */'views/smb/dns-zone/records-list'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/soa-record/id/:id/type/:type',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/soa-record" */'views/smb/dns-zone/soa-record'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/acl-records/id/:id/type/:type',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/acl-records" */'views/smb/dns-zone/acl-records'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/add-acl-record/id/:id/type/:type',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/add-acl-record" */'views/smb/dns-zone/add-acl-record'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/external-dns/id/:id/type/:type',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/external-dns" */'views/smb/dns-zone/external-dns'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/dns-zone/external-dns-edit/id/:id/type/:type/extensionId/:extensionId',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/dns-zone/external-dns-edit" */'views/smb/dns-zone/external-dns-edit'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/cp/settings-ui/edit-interface-view',
        element: async props => {
            const { data: { viewer, mode } } = await apolloClient.query({ query: gql`
                query {
                    viewer {
                        login
                        type
                    }
                    mode {
                        isViewSwitcherAllowed
                    }
                }
            ` });
            if (viewer.type !== 'ADMIN' || !mode.isViewSwitcherAllowed) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/admin/settings-ui/edit-interface-view" */'views/admin/settings-ui/edit-interface-view'),
                createGraphqlLoader(require('queries/EditInterfaceViewPage.graphql')),
            )(props);
        },
    },
    {
        path: '/admin/settings-ui/list-languages',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/settings-ui/list-languages" */'views/admin/settings-ui/list-languages'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/settings-ui',
        element: createPageDataLoader(),
    },
    {
        path: '/admin/database/settings',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/database/settings"*/'views/admin/database/settings'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/smb/web/web-server-settings/id/:id',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/smb/web/web-server-settings" */'views/smb/web/web-server-settings'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/admin/notifications',
        element: asyncElement(
            () => import(/* webpackChunkName: "views/admin/notifications/index" */'views/admin/notifications/index'),
            createPageDataLoader(),
        ),
    },
    {
        path: '/cp/settings-ui/branding',
        element: async props => {
            const { data: { viewer } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerBranding
                            }
                        }
                    }
                ` });
            if ((viewer.type === 'ADMIN' && viewer.permissions.manageServerBranding) || viewer.type === 'RESELLER') {
                return asyncElement(
                    () => import(/* webpackChunkName: "views/admin/settings-ui/branding" */'components/pages/BrandingPage'),
                    createGraphqlLoader(require('queries/BrandingPage.graphql')),
                )(props);
            }

            return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
        },
    },
    {
        path: '/cp/about',
        element: async props => {
            const { data: { viewer } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                        }
                    }
                ` });
            if (viewer.type !== 'ADMIN') {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/admin/about/index" */'views/admin/about/index'),
                createGraphqlLoader(require('queries/AboutPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/db',
        element: async props => {
            const { data: { viewer } } = await apolloClient.query({
                query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                    }
                `,
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServersPage'),
                createGraphqlLoader(require('queries/DatabaseServersPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/db/create',
        element: async props => {
            const { data: { viewer } } = await apolloClient.query({
                query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                    }
                `,
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerCreatePage'),
                createGraphqlLoader(require('queries/DatabaseServerCreatePage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/db/:id/properties',
        element: async props => {
            const { data: { viewer, databaseServer } } = await apolloClient.query({
                query: gql`
                    query ($id: ID!) {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                        databaseServer: node(id: $id) {
                            id
                        }
                    }
                `,
                variables: {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                },
                errorPolicy: 'ignore',
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb || !databaseServer) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerPropertiesPage'),
                createGraphqlLoader(require('queries/DatabaseServerPropertiesPage.graphql'), {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                }),
            )(props);
        },
    },
    {
        path: '/cp/server/db/:id/databases',
        element: async props => {
            const { data: { viewer, databaseServer } } = await apolloClient.query({
                query: gql`
                    query ($id: ID!) {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                        databaseServer: node(id: $id) {
                            id
                        }
                    }
                `,
                variables: {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                },
                errorPolicy: 'ignore',
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb || !databaseServer) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerDatabasesPage'),
                createGraphqlLoader(require('queries/DatabaseServerDatabasesPage.graphql'), {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                }),
            )(props);
        },
    },
    {
        path: '/cp/server/db/:id/change-password',
        element: async props => {
            const { data: { viewer, os, mode, databaseServer } } = await apolloClient.query({
                query: gql`
                    query ($id: ID!) {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerDb
                            }
                        }
                        os {
                            isWindows
                        }
                        mode {
                            isDemo
                        }
                        databaseServer: node(id: $id) {
                            id
                        }
                    }
                `,
                variables: {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                },
                errorPolicy: 'ignore',
            });
            if (viewer.type !== 'ADMIN' || !viewer.permissions.manageServerDb || !os.isWindows || mode.isDemo || !databaseServer) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/db"*/'components/pages/DatabaseServerChangePasswordPage'),
                createGraphqlLoader(require('queries/DatabaseServerChangePasswordPage.graphql'), {
                    id: btoa(`DatabaseServer:${props.params.id}`),
                }),
            )(props);
        },
    },
    {
        path: ['/cp/websites-diagnostic', '/cp/websites-diagnostic/id/:domainId'],
        element: async props => {
            const { data: { viewer, config } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                        }
                        config {
                            websitesDiagnostic {
                                enabled
                            }
                        }
                    }
                ` });
            if (viewer.type !== 'ADMIN' || !config.websitesDiagnostic.enabled) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/admin/websites-diagnostic/index" */'components/pages/WebsitesDiagnostic'),
                createGraphqlLoader(require('queries/WebsitesDiagnosticPage.graphql'), {}, 'network-only'),
            )(props);
        },
    },
    {
        path: '/cp/action-log',
        element: props => asyncElement(
            () => import(/* webpackChunkName: "components/pages/ActionLogPage" */'components/pages/ActionLogPage'),
            createGraphqlLoader(require('queries/ActionLogPage.graphql')),
        )(props),
    },
    {
        path: '/cp/system-time',
        element: props => asyncElement(
            () => import(/* webpackChunkName: "components/pages/SystemTimePage" */'components/pages/SystemTimePage'),
            createGraphqlLoader(require('queries/SystemTimePage.graphql')),
        )(props),
    },
    {
        path: '/cp/server-settings',
        element: props => asyncElement(
            () => import(/* webpackChunkName: "components/pages/ServerSettingsPage" */'components/pages/ServerSettingsPage'),
            createGraphqlLoader(require('queries/ServerSettingsPage.graphql')),
        )(props),
    },
    {
        path: '/cp/server/mail/settings',
        element: async props => {
            const { data: { viewer: { type, permissions } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerMail
                                manageServerMailAutodiscover
                                manageServerVirusProtection
                                manageServerSpamFilter
                            }
                        }
                    }
                ` });
            if (type !== 'ADMIN' ||
                (!permissions.manageServerMail && !permissions.manageServerMailAutodiscover && !permissions.manageServerVirusProtection && !permissions.manageServerSpamFilter)
            ) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailSettingsPage'),
                createGraphqlLoader(require('queries/ServerMailSettingsPage.graphql')),
                createGraphqlLoader(require('queries/ServerMailTabs.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/mail/black-list',
        exact: true,
        element: async props => {
            const { data: { viewer: { type, permissions } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerMailBlackWhiteLists
                            }
                        }
                    }
                ` });
            if (type !== 'ADMIN' || !permissions.manageServerMailBlackWhiteLists) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailBlackListPage'),
                createGraphqlLoader(require('queries/ServerMailBlackListPage.graphql')),
                createGraphqlLoader(require('queries/ServerMailTabs.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/mail/white-list',
        element: async props => {
            const { data: { viewer: { type, permissions } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerMailBlackWhiteLists
                            }
                        }
                    }
                ` });
            if (type !== 'ADMIN' || !permissions.manageServerMailBlackWhiteLists) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailWhiteListPage'),
                createGraphqlLoader(require('queries/ServerMailWhiteListPage.graphql')),
                createGraphqlLoader(require('queries/ServerMailTabs.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/server/mail/antivirus',
        element: async props => {
            const { data: { viewer: { type, permissions }, os: { isUnix } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerVirusProtection
                            }
                        }
                        os {
                            isUnix
                        }
                    }
                ` });
            if (type !== 'ADMIN' || isUnix || !permissions.manageServerVirusProtection) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailAntivirusPage'),
                createGraphqlLoader(require('queries/ServerMailAntivirusPage.graphql')),
                createGraphqlLoader(require('queries/ServerMailTabs.graphql')),
            )(props);
        },
    },
    {
        path: ['/cp/server/mail/spam-filter', '/cp/server/mail/spam-filter/:page'],
        element: async props => {
            const { data: { viewer: { type, permissions }, os: { isUnix } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerSpamFilter
                            }
                        }
                        os {
                            isUnix
                        }
                    }
                ` });

            if (isUnix) {
                return Promise.resolve({ redirect: props.params.page ? `/plesk/server/spam-filter/${props.params.page}` : '/plesk/server/spam-filter' });
            }

            if (type !== 'ADMIN' || !permissions.manageServerSpamFilter) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/server/mail" */'components/pages/ServerMailSpamFilterPage'),
                createGraphqlLoader(require('queries/ServerMailSpamFilterPage.graphql')),
                createGraphqlLoader(require('queries/ServerMailTabs.graphql')),
            )(props);
        },
    },
    {
        path: ['/cp/license/'],
        element: () => Promise.resolve({ redirect: '/cp/license/primary/' }),
    },
    {
        path: ['/cp/license/primary/', '/cp/license/primary/upload/', '/cp/license/primary/revert/'],
        element: async props => {
            const { data: { viewer: { type, permissions }, mode: { isDemo } } } = await apolloClient.query({
                query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerLicense
                            }
                        }
                        mode {
                            isDemo
                        }
                    }
                `,
            });
            if (type !== 'ADMIN'
                || !permissions.manageServerLicense
                || (isDemo && (props.pattern.path === '/cp/license/primary/upload/' || props.pattern.path === '/cp/license/primary/revert/'))
            ) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/license" */'components/pages/PrimaryKeyInfoPage'),
                ...[
                    createGraphqlLoader(require('queries/PrimaryKeyInfoPage.graphql')),
                    createGraphqlLoader(require('queries/MasterKeyProcessingState.graphql')),
                    props.pattern.path === '/cp/license/primary/upload/' ? createGraphqlLoader(require('queries/PrimaryKeyUploadPage.graphql')) : null,
                    props.pattern.path === '/cp/license/primary/revert/' ? createGraphqlLoader(require('queries/PrimaryKeyRevertPage.graphql')) : null,
                ].filter(Boolean),
            )(props);
        },
    },
    {
        path: ['/cp/license/additional/', '/cp/license/additional/upload/'],
        element: async props => {
            const { data: { viewer: { type, permissions }, mode: { isDemo } } } = await apolloClient.query({
                query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerLicense
                            }
                        }
                        mode {
                            isDemo
                        }
                    }
                `,
            });
            if (type !== 'ADMIN'
                || !permissions.manageServerLicense
                || (isDemo && (props.pattern.path === '/cp/license/additional/upload/'))
            ) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/license/additional" */'components/pages/AdditionalKeysPage'),
                ...[
                    createGraphqlLoader(require('queries/AdditionalKeysPage.graphql')),
                    props.pattern.path === '/cp/license/additional/upload/' ? createGraphqlLoader(require('queries/AdditionalKeyUploadPage.graphql')) : null,
                ].filter(Boolean),
            )(props);
        },
    },
    {
        path: ['/cp/license/additional/:id/', '/cp/license/additional/:id/revert/'],
        element: async props => {
            const { data: { viewer: { type, permissions }, mode: { isDemo }, additionalKey } } = await apolloClient.query({
                query: gql`
                    query ($id: ID!) {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerLicense
                            }
                        }
                        mode {
                            isDemo
                        }
                        additionalKey(id: $id) {
                            keyNumber
                        }                        
                    }
                `,
                variables: {
                    id: props.params.id,
                },
            });
            if (type !== 'ADMIN'
                || !permissions.manageServerLicense
                || !additionalKey
                || (isDemo && (props.pattern.path === '/cp/license/additional/:id/revert/'))
            ) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/license/additional" */'components/pages/AdditionalKeyInfoPage'),
                ...[
                    createGraphqlLoader(require('queries/AdditionalKeyInfoPage.graphql'), {
                        id: props.params.id,
                    }),
                    props.pattern.path === '/cp/license/additional/:id/revert/' ? createGraphqlLoader(require('queries/AdditionalKeyRevertPage.graphql'), {
                        id: props.params.id,
                    }) : null,
                ].filter(Boolean),
            )(props);
        },
    },
    {
        path: '/cp/firewall',
        exact: true,
        element: async props => {
            const { data: { viewer: { type, permissions }, os: { isUnix }, mode: { isDemo } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerFirewall
                            }
                        }
                        mode {
                            isDemo
                        }
                        os {
                            isUnix
                        }
                    }
                ` });
            if (type !== 'ADMIN' || isUnix || isDemo || !permissions.manageServerFirewall) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/firewall" */'components/pages/FirewallPage'),
                createGraphqlLoader(require('queries/FirewallPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/firewall/icmp',
        exact: true,
        element: async props => {
            const { data: { viewer: { type, permissions }, os: { isUnix }, mode: { isDemo } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerFirewall
                            }
                        }
                        mode {
                            isDemo
                        }
                        os {
                            isUnix
                        }
                    }
                ` });
            if (type !== 'ADMIN' || isUnix || isDemo || !permissions.manageServerFirewall) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/firewall" */'components/pages/FirewallIcmpPage'),
                createGraphqlLoader(require('queries/FirewallIcmpPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp/firewall/rules',
        exact: true,
        element: async props => {
            const { data: { viewer: { type, permissions }, os: { isUnix }, mode: { isDemo } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerFirewall
                            }
                        }
                        mode {
                            isDemo
                        }
                        os {
                            isUnix
                        }
                    }
                ` });
            if (type !== 'ADMIN' || isUnix || isDemo || !permissions.manageServerFirewall) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "views/firewall" */'components/pages/FirewallRulesPage'),
                createGraphqlLoader(require('queries/FirewallRulesPage.graphql')),
            )(props);
        },
    },
    {
        path: ['/cp/server/components', '/cp/server/components/default/:name'],
        element: async props => {
            const { data: { viewer: { type, permissions } } } = await apolloClient.query({ query: gql`
                    query {
                        viewer {
                            login
                            type
                            permissions {
                                manageServerComponents
                            }
                        }
                    }
                ` });

            if (type !== 'ADMIN' || !permissions.manageServerComponents) {
                return asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound'))(props);
            }

            return asyncElement(
                () => import(/* webpackChunkName: "components/pages/ServerComponentsPage" */'components/pages/ServerComponentsPage'),
                createGraphqlLoader(require('queries/ServerComponentsPage.graphql')),
            )(props);
        },
    },
    {
        path: '/cp',
        end: false,
        element: asyncElement(() => import(/* webpackMode: "eager" */'./components/NotFound')),
    },
];

routes = routes
    .map(route => Array.isArray(route.path) ? route.path.map(path => ({ ...route, path })) : route)
    .flat();

export const matchRoute = pathname => {
    if (!pathname) {
        return null;
    }
    for (const route of routes) {
        const match = matchPath(route, pathname);
        if (match) {
            return match;
        }
    }
    return null;
};

export const isClientSideRedirectAllowed = to => {
    const { pathname, search } = typeof to === 'string' ? parsePath(to) : to;
    if (search?.startsWith('?modals')) {
        return true;
    }

    return Boolean(matchRoute(pathname));
};

export default routes;
