// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

const toQueryParams = str => {
    if (!str) {
        return {};
    }

    const match = str.match(/([^?#]*)(#.*)?$/);
    const params = new URLSearchParams(match[1]);

    return [...params.entries()].reduce((acc, [key, val]) => {
        if (acc.hasOwnProperty(key)) {
            if (Array.isArray(acc[key])) {
                acc[key].push(val);
            } else {
                acc[key] = [acc[key], val];
            }
        } else {
            acc[key] = val;
        }

        return acc;
    }, {});
};


export default (url, params) => {
    if ('string' === typeof params) {
        const splitUrl = url.split('?', 2);
        params = params.startsWith('/') || params.startsWith('?') ? params : `?${params}`;
        const splitParams = params.split('?', 2);
        url = splitUrl[0].replace(/\/+$/, '') + splitParams[0];
        params = {
            ...toQueryParams(splitUrl[1] || ''),
            ...toQueryParams(splitParams[1] || ''),
        };
    }

    if (Object.prototype.toString.call(params) === '[object Object]') {
        params = Object.keys(params).map(function (name) {
            if ('undefined' === typeof params[name]) {
                return name;
            }
            return `${name}=${encodeURIComponent(params[name])}`;
        }).join('&');
    }

    if (!params) {
        return url;
    }
    return url + (-1 !== url.indexOf('?') ? '&' : '?') + params;
};
