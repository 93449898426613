// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useEffect, useState } from 'react';

const useLocalStorage = (key, initialValue) => {
    const [value, setValue] = useState(
        () => localStorage.getItem(key) || initialValue,
    );

    useEffect(() => {
        localStorage.setItem(key, value);
    }, [key, value]);

    return [value, setValue];
};

export default useLocalStorage;
