// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { Component, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import apolloClient from '../apolloClient';
import { ApolloProvider } from '@apollo/client';
import { Toaster } from '@plesk/ui-library';
import { Task } from 'jsw';
import Cookie from 'common/cookie';
import LocaleProvider from 'components/LocaleProvider';
import Routes from './Routes';
import PhpPage from './PhpPage';
import { useToasts, removeToast } from '../../helpers/toasts';

import TASK_PROGRESS_BAR_QUERY from 'queries/TaskProgressBar.graphql';

const CommonToaster = () => {
    const toasts = useToasts();
    return (<Toaster toasts={toasts} onToastClose={key => removeToast({ key })} data-type="toaster" />);
};

class App extends Component {
    state = {
        widgets: [],
    };

    componentDidMount() {
        apolloClient.query({
            query: TASK_PROGRESS_BAR_QUERY,
        }).then(({ data: { viewer, config } }) => {
            if (viewer.type === 'UNDEFINED') {
                return;
            }
            new Task.ProgressBar({
                wsEnabled: config.webSocket.enabled,
            });
        });
    }

    getConfirmation = (message, callback) => {
        try {
            const { callback: callbackReturnValue } = JSON.parse(message);

            if (typeof callbackReturnValue !== 'undefined') {
                callback(callbackReturnValue);
            } else {
                throw new Error();
            }
        } catch {
            // eslint-disable-next-line no-alert
            callback(window.confirm(message));
        }
    };

    addWidget(widget) {
        this.setState(({ widgets }) => ({
            widgets: [
                ...widgets,
                widget,
            ],
        }));
    }

    render() {
        return (
            <StrictMode>
                <ApolloProvider client={apolloClient}>
                    <LocaleProvider>
                        <BrowserRouter getUserConfirmation={this.getConfirmation}>
                            <Routes>
                                <PhpPage />
                            </Routes>
                            {this.state.widgets}
                            <CommonToaster />
                        </BrowserRouter>
                    </LocaleProvider>
                </ApolloProvider>
                {'true' === Cookie.get('debug-testing-mode') ? (
                    <>
                        <style type="text/css">
                            {`
                                *, *:before, *:after {
                                    transitions-delay: 0s !important;
                                    transition-duration: 0s !important;
                                    animation-duration: 0s !important;
                                }
                            `}
                        </style>
                        <div id="bottomAnchor" style={{ position: 'fixed', top: 0, zIndex: -1, opacity: 0.001 }} />
                    </>
                ) : null}
            </StrictMode>
        );
    }
}

export default App;
