// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

const prefillHelp = ({
    wizardTitle,
    pageTitle,
    objectName,
    breadcrumbs,
}) => {
    if (wizardTitle) {
        return wizardTitle;
    }

    let trimPreposition = !!objectName;

    let prefill = pageTitle ?? '';
    breadcrumbs = breadcrumbs ?? [];

    const matchLink = prefill && prefill.match(/(<a(.+)<\/a>)/);
    if (matchLink) {
        if (matchLink.index + matchLink[0].length === prefill.length) {
            trimPreposition = true;
        }

        prefill = prefill.replace(matchLink[0], '');
    }

    prefill = prefill.trim();

    if (trimPreposition) {
        // (1) If a Plesk page title contains some text and an object name then the input value is set to this text excluding one word preceding the object name.
        prefill = prefill.substring(0, prefill.lastIndexOf(' '));
    }

    if (prefill === '' && breadcrumbs.length > 1) {
        // (2) If a Plesk page title is empty or contains an object name only then the input value is set to the value of the last link in the breadcrumbs.
        prefill = breadcrumbs[breadcrumbs.length - 1]?.title;
    }

    return prefill;
};

export default prefillHelp;
