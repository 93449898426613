// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { Layout } from '@plesk/ui-library';
import Brand from 'plesk/components/Brand';
import CookiePolicy from 'plesk/components/CookiePolicy';
import StatusMessages from 'plesk/components/StatusMessages';

const LoginLayout = ({ children, actions, ...props }) => {
    useLayoutEffect(() => {
        document.body.classList.add('sid-login');
        return () => {
            document.body.classList.remove('sid-login');
        };
    }, []);

    return (
        <div className="login-page">
            <CookiePolicy layoutType="simplified" />
            <Layout
                type="simplified"
                className="login-page__inner"
                header={(
                    <div className="login-page-header">
                        <div className="login-page-header__brand">
                            <Brand />
                        </div>
                        {actions ? (
                            <div className="login-page-header__actions">
                                {actions}
                            </div>
                        ) : null}
                    </div>
                )}
                {...props}
            >
                <div id="main" className="login-page__content">
                    <StatusMessages />
                    {children}
                </div>
            </Layout>
        </div>
    );
};

LoginLayout.propTypes = {
    children: PropTypes.any,
    actions: PropTypes.any,
};

LoginLayout.defaultProps = {
    children: undefined,
    actions: undefined,
};

export const withLoginLayout = (Component, extraProps) => {
    const Wrapper = wrapperProps => {
        const {
            contentAddon,
            actions,
            ...props
        } = {
            ...wrapperProps,
            ...(typeof extraProps === 'function' ? extraProps(wrapperProps) : extraProps),
        };

        return (
            <LoginLayout
                contentAddon={contentAddon}
                actions={actions}
            >
                <Component {...props} />
            </LoginLayout>
        );
    };
    Wrapper.displayName = `withLoginLayout(${Component.displayName || Component.name || 'Component'})`;
    Wrapper.WrappedComponent = Component;

    return Wrapper;
};

export default LoginLayout;
