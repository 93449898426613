// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

/* eslint-disable react/jsx-max-depth */

import { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Action,
    Button,
    Column,
    Columns,
    ExtendedStatusMessage,
    ExtendedStatusMessageDescription,
    ExtendedStatusMessageTitle, Link, LocaleProvider, Spot,
    Text, Translate,
} from '@plesk/ui-library';
import { api, redirect, UAT } from 'jsw';
import './DynamicListBanner.less';

export const dynamicListBannerFeedbackHandler = () => {
    UAT.dispatchAction('DYNAMICLIST_BANNER_STARTED_FEEDBACK');
    return true;
};

export const DynamicListBannerHint = ({ selector }) => {
    const [closed, setClosed] = useState(false);

    const handleClose = () => {
        setClosed(true);
    };

    return !closed &&
        <Spot
            selector={selector}
            popup={{
                size: 'md',
                placement: 'bottom',
                onClose: handleClose,
                buttons: [(
                    <Button
                        key="finish"
                        onClick={handleClose}
                        intent="primary"
                        data-type="close-banner-hint"
                    >
                        <Translate content="closedBannerButton" />
                    </Button>
                )],
                children: <Translate content="closedBannerHint" />,
            }}
        />;
};

DynamicListBannerHint.propTypes = {
    selector: PropTypes.string.isRequired,
};

const DynamicListBanner = ({ bannerInfo, onTourStart, listMode, dropdownSelector }) => {
    const [showHint, setShowHint] = useState(false);
    const [discarded, setDiscarded] = useState(bannerInfo.discarded);
    const { enabled, feedbackUrl, locale, initialType } = bannerInfo;

    const handleCloseBanner = useCallback(() => {
        setDiscarded(true);
        setShowHint(true);
        api.post('/smb/web/dynamic-list-banner-hide');
        UAT.dispatchAction('DYNAMICLIST_BANNER_CLOSED');
    }, []);

    const handleSwitchView = useCallback(async () => {
        await api.post('/smb/web/dynamic-list-banner-switch');
        UAT.dispatchAction('DYNAMICLIST_BANNER_SWITCHED_VIEW', {
            listType: 'dynamic',
        });
        redirect('/smb/web/view?list-type=dynamic');
    }, []);

    const handleSwitchBackView = async () => {
        await api.post('/smb/web/dynamic-list-banner-switch');
        UAT.dispatchAction('DYNAMICLIST_BANNER_SWITCHED_VIEW', { listType: initialType });
        redirect(`/smb/web/view?list-type=${initialType}`);
    };

    const switchBackText = () => bannerInfo.initialType === 'active' ? 'switchBackToActive' : 'switchBackToClassic';

    const { switched } = bannerInfo;
    const isDynamic = listMode === 'dynamic';

    const intent = isDynamic ? 'success' : 'info';
    const emoji = isDynamic ? '🎉' : (!switched ? '👋' : '😢');

    const headerText = isDynamic ? 'switched.Header' : (!switched ? 'initial.Header' : 'switchedBack.Header');
    const contentText = isDynamic ? 'switched.Text' : (!switched ? 'initial.Text' : 'switchedBack.Text');

    const contentTextParams = {
        link: (
            <Link onClick={onTourStart}>
                <Translate component={Text} bold content="switched.LinkText" />
            </Link>
        ),
        listType: <Translate content={listMode === 'active' ? 'activeList' : 'classicList'} />,
    };

    return (
        <LocaleProvider messages={locale}>
            {(enabled && !discarded) &&
                <ExtendedStatusMessage
                    intent={intent}
                    image={<Text style={{ fontSize: '30px' }}>{emoji}</Text>}
                    className="dynamic-list-banner"
                >
                    <Columns>
                        <Column fill>
                            <Columns>
                                <Column maxWidth="70%">
                                    <ExtendedStatusMessageTitle>
                                        <Translate
                                            content={headerText}
                                        />
                                    </ExtendedStatusMessageTitle>
                                    <ExtendedStatusMessageDescription>
                                        <Translate content={contentText} params={contentTextParams} />
                                    </ExtendedStatusMessageDescription>
                                    <ExtendedStatusMessageDescription>
                                        <Columns gap="md">
                                            {!isDynamic && (
                                                <Column>
                                                    <Action icon="projects" onClick={handleSwitchView}>
                                                        <Translate
                                                            content="switchToDynamic"
                                                        />
                                                    </Action>
                                                </Column>
                                            )}
                                            {feedbackUrl && (
                                                <Column>
                                                    <Action
                                                        icon="feedback"
                                                        href={feedbackUrl}
                                                        target={'_blank'}
                                                        onClick={dynamicListBannerFeedbackHandler}
                                                    >
                                                        <Translate
                                                            content="shareFeedback"
                                                        />
                                                    </Action>
                                                </Column>
                                            )}
                                            {isDynamic && bannerInfo.initialType !== 'dynamic' && (
                                                <Column>
                                                    <Action
                                                        icon="arrow-back"
                                                        onClick={handleSwitchBackView}
                                                    >
                                                        <Translate
                                                            content={switchBackText()}
                                                        />
                                                    </Action>
                                                </Column>
                                            )}
                                        </Columns>
                                    </ExtendedStatusMessageDescription>
                                </Column>
                            </Columns>
                        </Column>
                        <Column>
                            <Button ghost icon="cross-mark" onToggle={handleCloseBanner} />
                        </Column>
                    </Columns>
                </ExtendedStatusMessage>}
            {showHint && <DynamicListBannerHint selector={dropdownSelector} />}
        </LocaleProvider>
    );
};

DynamicListBanner.propTypes = {
    onTourStart: PropTypes.func,
    bannerInfo: PropTypes.shape({
        locale: PropTypes.object.isRequired,
        enabled: PropTypes.bool.isRequired,
        discarded: PropTypes.bool.isRequired,
        feedbackUrl: PropTypes.string.isRequired,
        switched: PropTypes.bool.isRequired,
        initialType: PropTypes.string.isRequired,
    }).isRequired,
    listMode: PropTypes.string.isRequired,
    dropdownSelector: PropTypes.string.isRequired,
};

DynamicListBanner.defaultProps = {
    onTourStart: () => true,
};

export default DynamicListBanner;
