// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import 'core-js/stable';

/**
 * Workaround for Array.from(new Set(['1', '2'])) from prototype.js
 */
// eslint-disable-next-line no-extend-native
Set.prototype.toArray = function () {
    const array = [];
    this.forEach(item => array.push(item));
    return array;
};

/**
 * Workaround for Array.from(new Map([['a', 1], ['b', 2]])) from prototype.js
 */
// eslint-disable-next-line no-extend-native
Map.prototype.toArray = function () {
    const array = [];
    this.forEach((item, key) => array.push([key, item]));
    return array;
};
