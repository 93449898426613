// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import { useEffect, useState, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import TopBarProgress from 'react-topbar-progress-indicator';
import { useLocation, useNavigate } from 'react-router-dom';
import { redirect, Tooltip } from 'jsw';
import { matchRoute, isClientSideRedirectAllowed } from '../routes';

TopBarProgress.config({
    barThickness: 2,
    barColors: {
        0: '#28aade',
        1.0: '#28aade',
    },
    shadowBlur: 0,
    className: 'top-bar-progress',
});

const updateBottomAnchor = () => {
    const bottomAnchor = document.getElementById('bottomAnchor');

    if (bottomAnchor) {
        bottomAnchor.innerHTML = new Date().getTime().toString();
    }
};

const Routes = ({ children }) => {
    const [current, setCurrent] = useState(null);
    const lastLoadedMatch = useRef(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const match = useMemo(() => matchRoute(location.pathname), [location.pathname]);

    const load = match => {
        if (location.state?.reload) {
            delete location.state.reload;
            navigate(location, { replace: true });
        }
        lastLoadedMatch.current = match;

        setLoading(true);
        match.pattern.element(match)
            .then(element => {
                if (match.pathname !== lastLoadedMatch.current.pathname) {
                    return;
                }

                if (element.redirect) {
                    if (isClientSideRedirectAllowed(element.redirect)) {
                        navigate(element.redirect, { replace: true });
                    } else {
                        redirect(element.redirect);
                    }
                } else {
                    Tooltip.hide();
                    setCurrent(element);
                    setLoading(false);
                }
            })
            .catch(e => {
                setLoading(false);
                throw e;
            });
    };

    useEffect(() => {
        if (!match) {
            if (current !== null && history.action === 'POP') {
                window.location.reload();
            } else {
                setLoading(false);
                setCurrent(children);
            }
        } else if (location.state?.reload || match.pathname !== lastLoadedMatch.current?.pathname) {
            load(match);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [match?.pathname, location.state?.reload]);

    useEffect(() => {
        if (!loading) {
            window.history.scrollRestoration = 'manual';
            window.scrollTo(0, 0);

            setTimeout(updateBottomAnchor, 0);
        }
    }, [loading]);

    return (
        <>
            {loading && <TopBarProgress />}
            {current}
        </>
    );
};

Routes.propTypes = {
    children: PropTypes.any,
};

Routes.defaultProps = {
    children: undefined,
};

export default Routes;
