// Copyright 1999-2022. Plesk International GmbH. All rights reserved.

import * as AdminPanel from './admin-panel';
import * as Smb from './smb';
import * as common from './common';
import * as chk from './chk';
import Tooltip from './Tooltip';
import onReady from '../jsw/onReady';

window.AdminPanel = AdminPanel;
window.Smb = Smb;

Object.keys(common).forEach(fn => {
    window[fn] = common[fn];
});

Object.keys(chk).forEach(fn => {
    window[fn] = chk[fn];
});

onReady(() => {
    window.tooltip = new Tooltip(document);
});
